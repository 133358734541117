import $ from 'jquery'

export function loader( location ){

    $('a').not('.-active').not('[target="_blank"]').not('[href^="#"]').click(function(){
        $('html').addClass('-cross-page-loading');
    });

    $('a').click(function(){
        setTimeout(function(){
            $('html').removeClass('-cross-page-loading');
        }, 1000);
    })

    // First time load
    if( !$('html').hasClass('-loaded') ){
        setTimeout(function(){
            $('html').addClass('-loaded');
        }, 250);//4000
    }

    // Cross-page load
    else{
        setTimeout(function(){
            $('html').removeClass('-cross-page-loading');
        }, 300);
    }

}