import $ from 'jquery'

export function publishing() {

    var
        $button = $('#show-more'),
        $indicators = $('#indicators-hidden');

    $button.click(function(){
        $button.addClass('-hidden');
        $indicators.removeClass('-hidden');
    });

}