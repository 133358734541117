import $ from 'jquery'

export function imageZoom() {

    let
        $zoom = $('.image-zoom'),
        $items = $('.zoom, .block-img > img');

    // Open
    $items.each(function(){

        let
            $e = $(this),
            src = $e.attr('src');

        $e.click(function(){
            $zoom.css({
                'background-image': 'url(' + src + ')'
            });
            setTimeout(function(){
                $zoom.addClass('-visible');
            }, 250);
        });

    });

    // Close
    $zoom.click(function(){
        $zoom.removeClass('-visible');
        setTimeout(function(){
            $zoom.removeAttr('style');
        }, 350);
    });

    // Close via Esc
    $(document).keyup(function(key){
        if( key.keyCode === 27 ){
            $zoom.removeClass('-visible');
            setTimeout(function(){
                $zoom.removeAttr('style');
            }, 350);
        }
    });

}