import $ from 'jquery'

export function main() {
    

    // Set vatiable for viewport for mobile devices
    function setVh(){
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setVh();

    window.addEventListener('orientationchange', function() {
        setTimeout(function(){
            setVh();
        }, 500);
    });

    $(window).on('resize', function(){
        setVh();
    });


    // Sound trigger
    $('.sound-trigger').click(function(){
        $(this).toggleClass( '-muted' );
    });


    // Navigation trigger
    $('.navigation__trigger').click(function(){
        $('.navigation').toggleClass('-open');
    });

    $(document).keydown(function( event ){
        if( event.keyCode === 27 )
            $('.navigation').removeClass('-open');
    });

    $('.navigation__content__item').click(function(){
        $('.navigation').removeClass('-open');
    });


    // Funky inputs
    $('.ui-input').each(function(){

        var
            $e = $(this),
                $input = $e.find('.ui-input__field');

        $input.blur(function(){

            if( $input.val() != '' )
                $input.addClass('-non-empty');

            else
                $input.removeClass('-non-empty');

        });
        
    });


    // Dropdown
    $('.ui-input.-dropdown').each(function(){
        
        var
            $e = $(this),
            $field = $e.find('.ui-input__field'),
            $radios = $e.find('.ui-input__dropdown__item > input');

        $field.click(function(){
            if( !$e.hasClass('-open') ){
                $e.addClass('-open');
                $field.addClass('-open');
            }
            else{
                $e.removeClass('-open');
                $field.removeClass('-open');
            }
        });

        $radios.each(function(){
            
            var
                $r = $(this),
                value = $r.attr('value');

            $r.click(function(){

                $field.text( value );
                $e.removeClass('-open');
                $field.removeClass('-open').addClass('-filled');

            });

        });

    });

    $(document).click(function(event){
        if( $(event.target).closest('.ui-input.-dropdown').length === 0 ){
            $('.ui-input.-dropdown').removeClass('-open');
            $('.ui-input__field').removeClass('-open');
        }
    });

    $(document).keydown(function(event){
        if( event.keyCode === 27 ){
            $('.ui-input.-dropdown').removeClass('-open');
            $('.ui-input__field').removeClass('-open');
        }
    });


    // Hide and show the scroll-arrow on scroll
    // $('.rcs-inner-container').scroll(function(){
    //     if( $(this).scrollTop() > 50 )
    //         $('.scroll-arrow').addClass('-hide');
    //     else
    //         $('.scroll-arrow').removeClass('-hide');
    // });

    $('html, body').scroll(function(){
        if( $(this).scrollTop() > 50 )
            $('.scroll-arrow').addClass('-hide');
        else
            $('.scroll-arrow').removeClass('-hide');
    });

    $('.scroll-arrow').click(function(){
        $('html, body').animate({
            scrollTop: $(window).height() * 1.02
        }, 500);
    });


    // Call-to-action buttons (Bitrix Form)
    // $('[data-role="call-to-action"]').bind('click', function(){
    //     $('.b24-web-form-popup-btn-3').click();
    // });


    // Button animation
    if( $('.main-page__hero__title.-hidden').length > 0 ){
        $('.main-page__hero__title.-hidden').removeClass('-hidden').addClass('-animated');
    }
    if( $('.games__promo__button.-hidden').length > 0 ){
        $('.games__promo__button.-hidden').removeClass('-hidden').addClass('-animated');
    }

    // Home Page video debugging
    // $('.main-page__hero__video video').css({'opacity': '0'});
    // setTimeout(function(){
    //     $('.main-page__hero__video video').css({'opacity': '1'});
    // }, 1000);


    // Nintendo Switch America and Europe link resolver
    $('a.games__card__footer__stores__line__item.-switch').each(function(){

        if( $(this).attr('data-america-url') ){
            var
                $e = $(this),
                link = $e.attr('data-america-url'),
                link = link.slice( link.indexOf('/games'), link.length ),
                link = 'https://www.nintendo.com/regionselector/#prev=' + link;

            $e.attr( 'href', link );
        }

    });


    // PS America and Europe link resolver
    // $('a.games__card__footer__stores__line__item.-ps').each(function(){

    //     if( $(this).attr('data-america-url') ){
    //         var
    //             $e = $(this),
    //             link = $e.attr('data-america-url'),
    //             link = link.slice( link.indexOf('/games'), link.length ),
    //             link = 'https://www.nintendo.com/regionselector/#prev=' + link;

    //         $e.attr( 'href', link );
    //     }

    // });

    // var cc = null;
    // var cc_ru = "https://www.nintendo.ru/-/-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_fr = "https://www.nintendo.fr/Jeux/Jeux-a-telecharger-sur-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_be_nl = "https://www.nintendo.be/nl/Games/Nintendo-Switch-download-software/Tilt-Pack-1714118.html";
    // var cc_be_fr = "https://www.nintendo.be/fr/Jeux/Jeux-a-telecharger-sur-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_de = "https://www.nintendo.de/Spiele/Nintendo-Switch-Download-Software/Tilt-Pack-1714118.html";
    // var cc_es = "https://www.nintendo.es/Juegos/Programas-descargables-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_it = "https://www.nintendo.it/Giochi/Giochi-scaricabili-per-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_nl = "https://www.nintendo.nl/Games/Nintendo-Switch-download-software/Tilt-Pack-1714118.html";
    // var cc_at = "https://www.nintendo.at/Spiele/Nintendo-Switch-Download-Software/Tilt-Pack-1714118.html";
    // var cc_pt = "https://www.nintendo.pt/Jogos/Aplicacoes-de-download-da-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_ch_de = "https://www.nintendo.ch/de/Spiele/Nintendo-Switch-Download-Software/Tilt-Pack-1714118.html";
    // var cc_ch_fr = "https://www.nintendo.ch/fr/Jeux/Jeux-a-telecharger-sur-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_ch_it = "https://www.nintendo.ch/it/Giochi/Giochi-scaricabili-per-Nintendo-Switch/Tilt-Pack-1714118.html";
    // var cc_za = "https://www.nintendo.co.za/Games/Nintendo-Switch-download-software/Tilt-Pack-1714118.html";
    // var cc_uk = "https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Tilt-Pack-1714118.html";
    // var cc_us = "https://www.nintendo.com/games/detail/tilt-pack-switch/";
    // var cc_ca_us = "https://www.nintendo.com/en_CA/games/detail/tilt-pack-switch/";
    // var cc_ca_fr = "https://www.nintendo.com/fr_CA/";
    // var cc_la = "https://www.nintendo.com/es_LA/";
    // var cc_br = "https://www.nintendo.com/pt_BR/";
    // var cc_co = "https://www.nintendo.com/es_CO/";
    // var cc_ar = "https://www.nintendo.com/es_AR/";
    // var cc_cl = "https://www.nintendo.com/es_CL/";
    // var cc_pe = "https://www.nintendo.com/es_PE/";
    // $.ajax({
    //   url: "https://get.geojs.io/v1/ip/geo.js",
    //   dataType: "jsonp",
    //   jsonpCallback:"geoip",
    //   success: function(data) {

    //     console.log( data );

    //     console.log("IP: "+data.ip);
    //     console.log("Country: "+data.country);
    //         console.log("Country Code: "+data.country_code);
    //         cc = data.country_code;
    //         if (cc == 'RU'){
    //                 document.getElementById("test1").href = cc_ru;
    //         } else if (cc == 'FR'){
    //                 document.getElementById("test1").href = cc_fr;
    //         } else if (cc == 'BE'){
    //                         if (window.navigator.language == 'fr'){
    //                         document.getElementById("test1").href = cc_be_fr; } else {
    //                         document.getElementById("test1").href = cc_be_nl;
    //                         }
    //         } else if (cc == 'DE'){
    //                 document.getElementById("test1").href = cc_de;
    //         } else if (cc == 'ES'){
    //                 document.getElementById("test1").href = cc_es;
    //         } else if (cc == 'IT'){
    //                 document.getElementById("test1").href = cc_it;
    //         } else if (cc == 'NL'){
    //                 document.getElementById("test1").href = cc_nl;
    //         } else if (cc == 'AT'){
    //                 document.getElementById("test1").href = cc_at;
    //         } else if (cc == 'PT'){
    //                 document.getElementById("test1").href = cc_pt;
    //         } else if (cc == 'CH'){
    //                         if (window.navigator.language == 'fr'){
    //                         document.getElementById("test1").href = cc_ch_fr; }
    //                                 else if (window.navigator.language == 'it'){
    //                                 document.getElementById("test1").href = cc_ch_it;}
    //                                 else {
    //                                         document.getElementById("test1").href = cc_ch_de; }
    //         } else if (cc == 'ZA'){
    //                 document.getElementById("test1").href = cc_za;
    
    // }
    //         else if (cc == 'US'){
    //                 document.getElementById("test1").href = cc_us;
    //         } else if (cc == 'LA'){
    //                 document.getElementById("test1").href = cc_la;
    //         } else if (cc == 'BR'){
    //                 document.getElementById("test1").href = cc_br;
    //         } else if (cc == 'CO'){
    //                 document.getElementById("test1").href = cc_co;
    //         } else if (cc == 'AR'){
    //                 document.getElementById("test1").href = cc_ar;
    //         } else if (cc == 'CL'){
    //                 document.getElementById("test1").href = cc_cl;
    //         } else if (cc == 'PE'){
    //                 document.getElementById("test1").href = cc_pe;
    //         } else if (cc == 'CA'){
    //                         if (window.navigator.language == 'fr'){
    //                         document.getElementById("test1").href = cc_ca_fr; } else {
    //                         document.getElementById("test1").href = cc_ca_us;
    //                         }
    //         } else { document.getElementById("test1").href = cc_uk; }
    //         alert(window.navigator.language);
    //   }
    // });
    
    // $('a.games__card__footer__stores__line__item.-switch').each(function(){



    // });


}