import $ from 'jquery'
import Glide from '@glidejs/glide'

export function games() {

    // Video switcher
    $('.games__card__video').each(function(){

        var
            $e = $(this),
            $buttons = $e.find('.games__card__video__button'),
            $items = $e.find('.games__card__video__visible__item');

        $buttons.each(function(){

            var
                $b = $(this),
                id = $b.attr( 'data-id' ),
                $item = $e.find('.games__card__video__visible__item[data-id="' + id + '"]');

            if( $b.hasClass('-active') )
                $item.addClass('-active');

            $b.click(function(){

                if( !$b.hasClass('-active') ){

                    // Stop the videos
                    // if( $b.attr('data-id') === 'gameplay' ){
                    //     $('.games__card__video__visible__item').each(function(){
                    //         if( $(this).find('iframe') ){
                    //             $(this).find('iframe').attr( 'src', $(this).find('iframe').attr('src') );
                    //             $(this).closest('.games__card__video').find('.games__card__video__visible__item').removeClass('-active');
                    //             $(this).closest('.games__card__video').find('.games__card__video__visible__item[data-id="keyart"]').addClass('-active');
                    //             $(this).closest('.games__card__video').find('.games__card__video__button').removeClass('-active');
                    //             $(this).closest('.games__card__video').find('.games__card__video__button[data-id="keyart"]').addClass('-active');
                    //         }
                    //     });
                    // }
                    // $items.each(function(){
                    //     if( $items.find('iframe') && $(this).attr('data-id') !== id ){
                    //         $items.find('iframe').attr( 'src', $items.find('iframe').attr('src') );
                    //     }
                    // });

                    $buttons.removeClass('-active');
                    $items.removeClass('-active');

                    $b.addClass('-active');
                    $item.addClass('-active');

                }

            });

        });

    });

    // Video trailer ratios
    $('.game-section__content.-trailer').each(function(){

        var
            $e = $(this),
            k = 0.62;

        $e.css({
            'height': $e.width() * k
        });

    });

    if( $('body').hasClass('game-page') ){

        // Fixing GlideJS bug
        var resizeEvent = new Event('resize');
        window.dispatchEvent(resizeEvent);

        // Open slider to all vieport size

            var glide_collapsed = new Glide('.game-section__slider.-collapsed .glide', {
                perView: 2,
                focusAt: 'center',
                type: 'carousel'
            }).mount();

            var glide_expanded = new Glide('.game-section__slider.-expanded .glide', {
                perView: 1,
                focusAt: 'center',
                type: 'carousel'
            }).mount();

            // Open
            $('.game-section__slider.-collapsed img').each(function(){

                var
                    $e = $(this),
                    index = parseFloat( $e.attr('data-index') );

                $e.click(function(){
                    glide_expanded.go('=' + index);
                    setTimeout(function(){
                        $('.game-section__slider.-expanded').addClass('-show');
                    }, 250);
                });

            });

            // Close
            $('.game-section__slider.-expanded img').each(function(){

                var
                    $e = $(this),
                    index = parseFloat( $e.attr('data-index') );

                $e.click(function(){
                    glide_collapsed.go('=' + index);
                    setTimeout(function(){
                        $('.game-section__slider.-expanded').removeClass('-show');
                    }, 250);
                });

            });

            // Close via Esc
            $(document).keyup(function(key){
                if( key.keyCode === 27 )
                    $('.game-section__slider.-expanded').removeClass('-show');
            });

    }

}