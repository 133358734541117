import $ from 'jquery'

export function form() {
    

    var
        $form = $('.form'),
        $cross = $('.form__cross'),
        reg_email = /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,6}$/;


    // Open and close the form
    $('[data-role="call-to-action"]').each(function(){
        $(this).click(function(){
            $form.addClass('-open');
        });
    });
    $form.click(function(event){
        if( $(event.target).closest('.form__wrapper').length === 0 )
            $form.removeClass('-open');
    });
    $(document).keyup(function(key){
        if( key.keyCode === 27 )
            $form.removeClass('-open');
    });
    $cross.click(function(event){
        $form.removeClass('-open');
    });


    // Move to next step
    $('.form__step__button__link').each(function(){

        var
            $e = $(this),
            next_step = $e.attr('data-to');

        $e.click(function(){
            if( !$e.hasClass('-disabled') && $e.attr('data-to') !== '3' ){

                // Form sending (steps)
                console.log( 'DATA (Step ' + (next_step - 1) + '): ' + JSON.stringify( $('form').serializeArray() ) );
                $('.form__wrapper').addClass('-loading');
                $.ajax({
                    type: 'POST',
                    url: 'https://corptst.supergg.com/ga/PoluLeadFromSite.php',
                    data: JSON.stringify( $('form').serializeArray() ),
                    success: function( response ){
                        if( response == 1 ){
                            $form.removeClass('-step-1').removeClass('-step-2').removeClass('-step-3').removeClass('-step-4');
                            $form.addClass('-step-' + next_step);
                            $('.form__wrapper').removeClass('-loading');
                        }
                    }
                });
                
            }
        });

    });


    // Remove invalid class on focus
    $('.ui-input__field').focus(function(){
        $('.ui-input__field[data-required="true"], .ui-checkbox__input[data-required="true"]').removeClass('-invalid');
    });

    // Step 1
    $('.form__step.-step-1 .form__step__button__link').click(function(){

        $('.form__step.-step-1 .ui-input__field[data-required="true"]').each(function(){

            if( $(this).val() === '' )
                $(this).addClass('-invalid');

            if( $(this).attr('name') === 'e-mail' && reg_email.test( $('input[name="e-mail"]').val() ) === false )
                $(this).addClass('-invalid');

        });

        $('.form__step.-step-1 .ui-checkbox__input[data-required="true"]').each(function(){
            if( !$(this).is(':checked') )
                $(this).addClass('-invalid');
        });

    });

    $('.form__step.-step-1 .ui-input__field').keyup(function(){
        if(
            $('.ui-input__field[name="first-name"]').val() !== '' &&
            $('.ui-input__field[name="last-name"]').val() !== '' &&
            $('.ui-input__field[name="company-name"]').val() !== '' &&
            $('.ui-input__field[name="e-mail"]').val() !== '' &&
            reg_email.test( $('.ui-input__field[name="e-mail"]').val() ) === true &&
            $('.ui-checkbox__input[name="terms-agreement"]').is(':checked') &&
            $('.ui-input__field[name="project-country"]').val() !== '' &&
            $('.ui-input__field[name="team-size"]').val() !== ''
        )
            $('.form__step.-step-1 .form__step__button__link').removeClass('-disabled');
        else
            $('.form__step.-step-1 .form__step__button__link').addClass('-disabled');
    });

    $('.form__step.-step-1 .ui-checkbox__input[name="terms-agreement"]').change(function(){
        if(
            $('.ui-input__field[name="first-name"]').val() !== '' &&
            $('.ui-input__field[name="last-name"]').val() !== '' &&
            $('.ui-input__field[name="company-name"]').val() !== '' &&
            $('.ui-input__field[name="e-mail"]').val() !== '' &&
            reg_email.test( $('.ui-input__field[name="e-mail"]').val() ) === true &&
            $('.ui-checkbox__input[name="terms-agreement"]').is(':checked') &&
            $('.ui-input__field[name="project-country"]').val() !== '' &&
            $('.ui-input__field[name="team-size"]').val() !== ''
        )
            $('.form__step.-step-1 .form__step__button__link').removeClass('-disabled');
        else
            $('.form__step.-step-1 .form__step__button__link').addClass('-disabled');
    });

    // Step 2
    $('.form__step.-step-2 .form__step__button__link').click(function(){

        $('.form__step.-step-2 .ui-input__field[data-required="true"]').each(function(){
            if( $(this).val() === '' )
                $(this).addClass('-invalid');
        });

        $('.form__step.-step-2 .ui-checkbox__input[data-required="true"]').each(function(){
            if( !$(this).is(':checked') && $('.form__step.-step-2 .ui-checkbox__input:checked').length < 1 )
                $(this).addClass('-invalid');
        });

        if( !$(this).hasClass('-disabled') ){

            // Form sending
            console.log( 'DATA (form completed): ' + JSON.stringify( $('form').serializeArray() ) );
            $('.form__wrapper').addClass('-loading');
            $.ajax({
                type: 'POST',
                url: 'https://corptst.supergg.com/ga/LeadFromSite.php',
                data: JSON.stringify( $('form').serializeArray() ),
                success: function( response ){
                    if( response == 1 ){
                        $form.removeClass('-step-1').removeClass('-step-2').removeClass('-step-3').removeClass('-step-4');
                        $form.addClass('-step-3');
                        $('.form__wrapper').removeClass('-loading');
                    }
                }
            });

        }

    });

    $('.form__step.-step-2 .ui-input__field').keyup(function(){
        if(
            $('.ui-input__field[name="project-name"]').val() !== ''
        )
            $('.form__step.-step-2 .form__step__button__link').removeClass('-disabled');
        else
            $('.form__step.-step-2 .form__step__button__link').addClass('-disabled');
    });


}