import $ from 'jquery'
import Bowser from "bowser"

import { main } from './src/js/main'
import { form } from './src/js/form'
import { loader } from './src/js/loader'
import { games } from './src/js/games'
import { publishing } from './src/js/publishing'
import { contact } from './src/js/contact'
import { news } from './src/js/news'
import { imageZoom } from './src/js/image-zoom'

export const onRouteUpdate = ({
    location
}) => {

    $(document).ready(function(){

        const current_location = location.pathname.replace(/\/$/,'');

        // Video resolver
        const device = ( typeof window !== 'undefined' ) ? Bowser.parse(window.navigator.userAgent).platform.type : 'desktop';
        const is_mobile = ( device === 'mobile' || device === 'tablet' );

        if ( is_mobile )
            $('.games__card__video__visible__item video').remove();
        
        loader( current_location );

        var wait_loading = setInterval(function(){

            if( $('html').hasClass('-loaded') && !$('html').hasClass('-cross-page-loading') ){
    
                clearInterval( wait_loading );

                main();
                
                form();
                
                if( current_location.startsWith( '/games' ) ){
                    games();
                    imageZoom();
                }
                    
                else if( current_location.startsWith( '/publishing' ) )
                    publishing();
                    
                // else if( current_location.startsWith( '/contact' ) )
                //     contact();
                    
                else if( current_location.startsWith( '/news' ) ){
                    news();
                    imageZoom();
                }

            }
        }, 250);

    });

}