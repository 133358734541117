// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-end-user-license-agreement-js": () => import("./../../../src/pages/end-user-license-agreement.js" /* webpackChunkName: "component---src-pages-end-user-license-agreement-js" */),
  "component---src-pages-fund-js": () => import("./../../../src/pages/fund.js" /* webpackChunkName: "component---src-pages-fund-js" */),
  "component---src-pages-games-alchemist-adventure-js": () => import("./../../../src/pages/games/alchemist-adventure.js" /* webpackChunkName: "component---src-pages-games-alchemist-adventure-js" */),
  "component---src-pages-games-broken-lines-js": () => import("./../../../src/pages/games/broken-lines.js" /* webpackChunkName: "component---src-pages-games-broken-lines-js" */),
  "component---src-pages-games-deadlink-js": () => import("./../../../src/pages/games/deadlink.js" /* webpackChunkName: "component---src-pages-games-deadlink-js" */),
  "component---src-pages-games-deflector-js": () => import("./../../../src/pages/games/deflector.js" /* webpackChunkName: "component---src-pages-games-deflector-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-metaphora-js": () => import("./../../../src/pages/games/metaphora.js" /* webpackChunkName: "component---src-pages-games-metaphora-js" */),
  "component---src-pages-games-raji-js": () => import("./../../../src/pages/games/raji.js" /* webpackChunkName: "component---src-pages-games-raji-js" */),
  "component---src-pages-games-retro-machina-js": () => import("./../../../src/pages/games/retro-machina.js" /* webpackChunkName: "component---src-pages-games-retro-machina-js" */),
  "component---src-pages-games-tiltpack-js": () => import("./../../../src/pages/games/tiltpack.js" /* webpackChunkName: "component---src-pages-games-tiltpack-js" */),
  "component---src-pages-games-wardens-js": () => import("./../../../src/pages/games/wardens.js" /* webpackChunkName: "component---src-pages-games-wardens-js" */),
  "component---src-pages-games-wonhon-js": () => import("./../../../src/pages/games/wonhon.js" /* webpackChunkName: "component---src-pages-games-wonhon-js" */),
  "component---src-pages-games-zelter-js": () => import("./../../../src/pages/games/zelter.js" /* webpackChunkName: "component---src-pages-games-zelter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-publishing-js": () => import("./../../../src/pages/publishing.js" /* webpackChunkName: "component---src-pages-publishing-js" */),
  "component---src-pages-terms-of-services-js": () => import("./../../../src/pages/terms-of-services.js" /* webpackChunkName: "component---src-pages-terms-of-services-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

