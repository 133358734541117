import $ from 'jquery'

export function news() {


    $('.news__cats__list__item').click(function(){

        let
            $e = $(this);

        $e.click(function(){
            $('html, body').scrollTop(0);
        });

    });


    function hash(){

        let hash = window.location.hash.toLowerCase().replace('#', '');
        $('.news__roll__item').hide();

        if( hash === 'all' )
            hash = '';
        
        if( hash !== '' ){
            $('.news__cats__list__item').removeClass('-active');
            $('.news__cats__list__item[data-cat="' + hash + '"]').addClass('-active');
            $('.news__roll__item').addClass('-hidden');
            setTimeout(function(){
                setTimeout(function(){
                    $('.news__roll__item.cat-' + hash).show().removeClass('-hidden');
                }, 150);
            }, 500);
        }
        else{
            $('.news__cats__list__item').removeClass('-active');
            $('.news__cats__list__item[data-cat="all"]').addClass('-active');
            $('.news__roll__item').addClass('-hidden');
            setTimeout(function(){
                setTimeout(function(){
                    $('.news__roll__item').show().removeClass('-hidden');
                }, 150);
            }, 500);
        }

    }

    // Init
    hash();

    // Change
    $(window).bind('hashchange', function(){
        hash();
    });


}